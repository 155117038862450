<template>
  <section>
    <div class="text-center">
      <div class="friendly-4">
        Contact Us
      </div>
    </div>
    <div class="text-right mx-10 mt-6">
      <v-form @submit.prevent="doContact" ref="form">
        <v-text-field v-model="name" outlined label="Name" :rules="inputs.rules.simplyRequired"></v-text-field>
        <v-text-field v-model="phoneNumber" outlined label="Phone Number" :rules="inputs.rules.phoneNumberRequired" v-mask="['(###) ###-####']"></v-text-field>
        <v-text-field v-model="email" outlined label="Email address" :rules="inputs.rules.emailRequired"></v-text-field>
        <v-textarea v-model="message" outlined label="Comments" hint=""></v-textarea>
        <v-btn class="mr-4" type="submit" :loading="isLoading">
          Contact
        </v-btn>
      </v-form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactForm',
  data: () => ({
    isLoading: false,
    name: String(),
    phoneNumber: String(),
    email: String(),
    message: String()
  }),
  methods: {
    doInit () {
      this.name = String()
      this.phoneNumber = String()
      this.email = String()
      this.message = String()
    },
    doContact () {
      if (this.$refs.form.validate()) {
        this.isLoading = true

        this.$services.contact.doNotifyCompany(this.name, this.phoneNumber, this.email, this.message).then(_ => {
          alert('We will be in contact, Thank you!')
          this.doInit()
          this.$refs.form.resetValidation()
        }).catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
        })
      }
    }
  },
  mounted () {
    this.doInit()
  }
}
</script>

<style scoped>

</style>
