<template>
  <section>
    <div class="text-left">
      <div class="friendly-4 text-content">
        We are <span>Phoenix, Arizona's</span>
      </div>
      <div class="scrolling-words-container friendly-4 mt-1 text-content">
        <span class="mb-1">Premier</span>
        <div class="scrolling-words-box friendly-4">
          <ul>
            <li style="font-weight: 600;" class="text-content">Scuba Diving</li>
            <li class="text-content">Adventure</li>
            <li class="text-content">Travel️</li>
            <li class="text-content">
              <v-avatar size="30px">
                <v-img src="https://s3.us-east-1.wasabisys.com/sea2sea/media/padi-logo.png"></v-img>
              </v-avatar>
              <span class="ml-2">
                PADI 5 Star
              </span>
            </li>
            <li style="font-weight: 600;" class="text-content">Scuba Diving</li>
          </ul>
        </div>
      </div>
      <div class="friendly-4 text-content">
        Development center
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroCommentAnimation'
}
</script>

<style scoped lang="scss">
.text-content {
  height: 46px;
  font-size: 46px;
  line-height: 46px;
  margin: 10px 0;
}

.scrolling-words-container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.scrolling-words-box {
    height: 4rem;
    overflow: hidden;
  ul {
      margin: 0 0.625rem;
      padding: 0;
      animation: scrollUp 8s infinite;
      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 3rem;
        list-style: none;
        margin: 0.2rem 0 0.6rem 0;
      }
    }
}

@media screen and (max-width: 620px) {
  .text-content {
    height: 30px !important;
    font-size: 30px !important;
    line-height: 30px !important;
  }
  .scrolling-words-box {
    height: 3rem !important;
    ul {
      li {
        height: 2rem;
      }
    }
  }

}

$item-count: 5;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25) - 10%}, #{$i * 25%} {
      transform: translateY((-100% / $item-count) * $i);
    }
  }
}
</style>
