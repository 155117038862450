<template>
  <div class="expanded">
    <video-background :src="videoSrc" style="height: calc(100vh - 60px);" class="">
      <v-overlay :absolute="true" :opacity="0.5" z-index="4" class="insetShadow">
        <v-flex align-center>
          <div>
            <slot>
              This is the center content
            </slot>
          </div>
        </v-flex>
      </v-overlay>
    </video-background>
  </div>
</template>

<script>
export default {
  name: 'VideoOverlay',
  props: {
    maxHeight: {
      type: Number,
      default: () => 400
    },
    videoSrc: {
      type: String(),
      default: () => ''
    }
  }
}
</script>

<style scoped>
.insetShadow {
  -webkit-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
  box-shadow: inset 0px -50px 100px -20px rgba(0,0,0,1);
}
</style>
