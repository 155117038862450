<template>
  <section>
    <VideoOverlay :video-src="welcomeHero.videoSrc">
      <v-img contain :max-height="180" :src="welcomeHero.imageSrc"></v-img>
    </VideoOverlay>
    <v-container>
      <div class="my-10">
        <hero-comment-animation></hero-comment-animation>
      </div>
    </v-container>
    <div class="expanded">
      <v-container class="pt-6 pb-8">
        <v-row>
          <v-col v-for="item in heroOptions" :key="item.title" cols="12" sm="4">
            <hover-card-action :title="item.title" :background-image="item.background" :content="item.content" :route-to="item.routeTo"></hover-card-action>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="pt-0">
      <div v-if="!courses.isEmpty">
        <span v-if="courses.isLoading">
            <v-progress-linear rounded indeterminate height="10" class="my-10"></v-progress-linear>
        </span>
        <span v-else>
          <div class="mt-4 mb-8 pa-2 text-center friendly-4">Courses we offer</div>
          <top-carousel-list :courses="courses.data.slice(0, courses.data.length >= 10 ? 10 : courses.data.length)" class="expanded"></top-carousel-list>
        </span>
      </div>
      <div v-else>
        <HorizontalLineDivider class="primary mb-0 mt-6"></HorizontalLineDivider>
      </div>

      <div v-if="!trip.isEmpty">
        <span v-if="trip.isLoading">
            <v-progress-linear rounded indeterminate height="10" class="my-10"></v-progress-linear>
        </span>
        <span v-else>
          <HorizontalLineDivider class="primary mb-0 mt-6"></HorizontalLineDivider>
          <span v-if="trip.data.dates.length === 1">
              <quick-info-action :title="trip.data.name" :description="`Join us on a ${trip.data.dates[0].nights} Night trip. Reserve your spot today!`" :route-to-name="'view-trip'" :route-params="{ id: trip.data.id }" :poster="trip.data.images[0].url"></quick-info-action>
          </span>
          <span v-else>
              <quick-info-action :title="trip.data.name" :description="'Join us on a trip of a lifetime!'" route-to-name="view-trip" :route-params="{ id: trip.data.id }" :poster="trip.data.images[0].url"></quick-info-action>
          </span>
          <HorizontalLineDivider class="primary mb-0"></HorizontalLineDivider>
        </span>
      </div>
      <div v-else>
        <HorizontalLineDivider class="primary mb-0 mt-6"></HorizontalLineDivider>
      </div>
    </v-container>

    <v-container class="mb-12 mt-4">
      <v-row justify="center">
        <v-col>
          <div class="text-center">
            <div class="friendly-4">
              About us
            </div>
            <div class="my-6 px-6">
              Sea2Sea Scuba's combination of decades of diving expertise, along with the highest quality instructors, and a friendly and welcoming environment makes us the Premier Scuba Diving Development center in Phoenix, AZ!
            </div>
            <v-carousel hide-delimiters :cycle="true" :show-arrows="false" class="rounded-lg mx-auto" style="height: 400px; width: 600px">
              <v-carousel-item :src="'https://via.placeholder.com/1920x1080.png?text=Team%20Photo%20A%201920x1080'"></v-carousel-item>
              <v-carousel-item :src="'https://via.placeholder.com/1920x1080.png?text=Team%20Photo%20B%201920x1080'"></v-carousel-item>
              <v-carousel-item :src="'https://via.placeholder.com/1920x1080.png?text=Team%20Photo%20C%201920x1080'"></v-carousel-item>
            </v-carousel>
          </div>
        </v-col>
        <v-col>
          <contact-form></contact-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import HorizontalLineDivider from '@/components/HorizontalLineDivider'
import VideoOverlay from '@/components/VideoOverlay'
import HoverCardAction from '@/components/HoverCardAction'
import QuickInfoAction from '@/components/QuickInfoAction'
import ContactForm from '@/components/etc/contactForm.vue'
import HeroCommentAnimation from '@/components/HeroCommentAnimation.vue'
import TopCarouselList from '@/components/course/topCarouselList.vue'

export default {
  name: 'Home',
  components: { TopCarouselList, HeroCommentAnimation, ContactForm, QuickInfoAction, HoverCardAction, HorizontalLineDivider, VideoOverlay },
  data: () => ({
    welcomeHero: {
      videoSrcTesting: 'https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/V7hI1imKeijyy0rxl/videoblocks-divers-jumping-off-boat-into-ocean-scuba-excursion-slow-motion_rtnzka0-n4__b43fba84109de6b11f4115000c5b6e70__P360.mp4',
      videoSrc: 'https://artsandculture.withgoogle.com/gcs/national-parks-service/en-us/9f885369-a52a-4a0b-8b2f-f3e5e41cdd54.mp4',
      imageSrc: 'https://s3.us-east-1.wasabisys.com/sea2sea/media/Logo-White-Text.png'
    },
    heroOptions: [
      {
        title: 'Join a class',
        content: 'Whether you are brand new to diving, or an experienced scuba diver we offer training at all levels! From recreational open water scuba certification and specialties to closed-circuit rebreather, to all levels of technical diving—we’ve got you covered! Join us for the experience of a lifetime!',
        background: 'https://s3.us-east-1.wasabisys.com/sea2sea/media/Classes.jpg',
        routeTo: 'courses'
      },
      {
        title: 'Get Equipment',
        content: 'We carry all of diving’s top brands at our brick and mortar location in Phoenix, AZ as well as in our extensive online shop! Recreational, technical, free-diving and more gear is all at Sea 2 Sea Scuba. Our experienced service technician is also available for all of your gear service needs!',
        background: 'https://s3.us-east-1.wasabisys.com/sea2sea/media/Equipment.jpg',
        routeTo: 'retail'
      },
      {
        title: 'Travel away',
        content: 'Join us on a dive trip this year! We arrange a variety of trip, from easy weekends in California and Mexico to far-flung bucket list destinations all over the world!  Check out our upcoming trip for more information. We can’t wait for you to join our next adventure!',
        background: 'https://s3.us-east-1.wasabisys.com/sea2sea/media/Trip.jpg',
        routeTo: 'trips'
      }
    ],
    courses: {
      isEmpty: false,
      isLoading: true,
      data: []
    },
    trip: {
      isEmpty: false,
      isLoading: true,
      data: null
    }
  }),
  methods: {
    doLoadPopularCourses () {
      this.courses.isLoading = true
      this.$services.course.doGetAll().then(courses => {
        if (courses.length === 0) {
          this.courses.isEmpty = true
        }

        this.courses.data = courses
      }).catch(_ => {
        this.courses.isEmpty = true
      }).finally(_ => {
        this.courses.isLoading = false
      })
    },
    doLoadClosestTrip () {
      this.trip.isLoading = true
      this.$services.trip.doGetClosest().then(tripData => {
        if (tripData === null) {
          this.trip.isEmpty = true
        }

        this.trip.data = tripData
      }).catch(_ => {
        this.trip.isEmpty = true
      }).finally(_ => {
        this.trip.isLoading = false
      })
    }
  },
  mounted () {
    this.doLoadPopularCourses()
    this.doLoadClosestTrip()
  }
}
</script>

<style scoped lang="scss">

</style>
