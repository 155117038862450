<template>
  <v-card class="mx-auto rounded-lg">
    <v-img class="hidden-sm-and-down rounded-lg" height="300px" :src="backgroundImage">
      <v-hover v-slot="{ hover }" open-delay="200">
        <v-overlay :absolute="true" :opacity="0.8">
          <v-card-text class="text-center" @click="$router.push({ name: routeTo })" style="cursor: pointer;">
            <div class="friendly-3">{{ title }}</div>
            <v-expand-transition>
              <div v-if="hover" class="mt-6 text-body-1 text-left">{{ content }}</div>
            </v-expand-transition>
          </v-card-text>
        </v-overlay>
      </v-hover>
    </v-img>
    <v-img class="hidden-md-and-up rounded-t-lg" height="100px" :src="backgroundImage">
      <v-overlay :absolute="true" :opacity="0.8">
        <span class="friendly-2">{{ title }}</span>
      </v-overlay>
    </v-img>
    <v-card-text class="hidden-md-and-up">
      <div>
        <p class="text-body-1 mb-2" v-snip="{ lines: 3, mode: 'js', midWord: true }">{{ content }}</p>
        <v-btn block @click="$router.push({ name: routeTo })" class="mt-2">
          Learn more
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'HoverCardAction',
  props: {
    title: {
      type: String,
      default: () => 'Title'
    },
    content: {
      type: String,
      default: () => 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus ultricies tristique nulla aliquet enim tortor. Varius duis at consectetur lorem.'
    },
    backgroundImage: {
      type: String,
      default: () => 'https://picsum.photos/1920/1080?val=1'
    },
    routeTo: {
      type: String,
      default: () => 'home'
    }
  }
}
</script>

<style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
</style>
