<template>
  <section>
    <div style="position: relative;" v-intersect="onIntersect">
    <hooper :settings="carouselSettings" style="height: 100%;" :class="howToMobile ? 'blurry' : ''" @beforeSlide="beforeSlide" @afterSlide="afterSlide">
      <slide v-for="(course, index) in courses" :key="index" :index="index" class="pr-8 pb-6">
        <v-card elevation="10" class="rounded-lg mr-4" @click="doGoToCourse(course.id)">
          <v-img :aspect-ratio="1" :src="(course.images[0] !== undefined && 'url' in course.images[0] ? course.images[0].url : `https://via.placeholder.com/1920x1080.png?text=${course.name} Course`)">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-row>
            </template>
            <div class="title-gradient-background">
              <v-avatar size="42" class="ma-2">
                <v-img contain :src="course.organization.logoUrl"></v-img>
              </v-avatar>
              <span class="font-weight-bold white--text">{{ course.organization.name }}</span>
              <v-chip class="font-weight-bold ma-3 float-end hidden-md-and-down">
                ${{ course.cents / 100 }}
              </v-chip>
            </div>
          </v-img>
          <v-card-title>
            <p v-snip="{ lines: 1, mode: 'js', midWord: true }">{{ course.name }}</p>
          </v-card-title>
          <v-card-subtitle style="height: 100px;">
            <p v-snip="{ lines: 4, mode: 'js', midWord: true }">
              {{ course.subtitle }}
            </p>
          </v-card-subtitle>
        </v-card>
      </slide>
      <Navigation slot="hooper-addons" class="hidden-sm-and-down"></Navigation>
    </hooper>
      <v-fade-transition>
        <div v-if="this.howToMobile" style="position: absolute; top:0; left:0; width: 100%; height: 100%; background: rgba(255,255,255,0);" class="hidden-sm-and-up text-center">
          <div class="Absolute-Center">
            <div class="title white--text font-weight-bold">
              Swipe across to view courses
            </div>
            <font-awesome-icon ref="handPointer" icon="fa-solid fa-hand-point-up" size="6x" class="mt-16 white--text handPointer"/>
          </div>
        </div>
      </v-fade-transition>
    </div>
  </section>
</template>

<script>
import { Hooper, Slide, Navigation } from 'hooper'
import 'hooper/dist/hooper.css'
export default {
  name: 'topCarouselList',
  components: {
    Hooper,
    Slide,
    Navigation
  },
  props: {
    courses: {
      type: Array
    }
  },
  data: () => ({
    howToMobile: false,
    currentlySliding: false,
    carouselSettings: {
      centerMode: true,
      wheelControl: false,
      infiniteScroll: true,
      breakpoints: {
        600: {
          itemsToShow: 3
        },
        960: {
          itemsToShow: 4
        },
        1264: {
          itemsToShow: 5
        },
        1904: {
          itemsToShow: 7
        }
      }
    }
  }),
  methods: {
    onIntersect (entries, observer) {
      if (entries[0].isIntersecting && this.howToMobile) {
        this.$refs.handPointer.classList.add('animate-glide-left')

        setTimeout(() => {
          this.howToMobile = false
        }, 2500)
      }
    },
    beforeSlide () {
      this.currentlySliding = true
    },
    afterSlide () {
      this.currentlySliding = false
    },
    doGoToCourse (id) {
      if (!this.currentlySliding) {
        this.$router.push({ name: 'view-course', params: { id: id } })
      }
    },
    mobileSet () {
      const width = (window.innerWidth > 0) ? window.innerWidth : screen.width

      if (width <= 600) {
        this.howToMobile = true
      }
    }
  },
  mounted () {
    this.mobileSet()
  }
}
</script>

<style scoped>
.blurry {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  cursor: pointer !important;
  pointer-events: none !important;
}

.Absolute-Center {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%);
}

.title-gradient-background {
  background: rgb(2,0,36);
  background: linear-gradient(135deg, rgba(2,0,36,0.5) 0%, rgba(255,255,255,0.3) 100%);
}

.animate-glide-left {
  animation-delay: 0.5s;
  animation: glide-left 1s ease-in;
  -webkit-animation: glide-left 1s ease-in;
}

::v-deep .handPointer path {
  stroke: black;
  stroke-width: 10;
}

@keyframes glide-left {
  0% { transform: translateX(0%); }
  80% { transform: translateX(-100px) rotate(-15deg);}
  100% { transform: translateX(0%) rotate(0); }
}
</style>
